<template>
  <div class="all-width screen-height">

    <v-card :loading="loading" class="all-width screen-height shadow-off" :disabled="loading" loader-height="2">
      <v-card-text class="px-2">
        <div class="d-flex align-center px-2 justify-space-between">
          <div class="d-flex">
            <h1 class="d-flex align-center ma-0 text-h5 black--text font-weight-bold">Tasklist</h1>
            <v-tooltip bottom content-class="tooltip-bottom" >
              <template v-slot:activator="{ on, attrs }"> 
                  <v-btn 
                      v-bind="attrs"
                      v-on="on"
                      class="shadow-off ml-1" icon @click="refreshData()"><v-icon
                      size="24">mdi-refresh</v-icon>
                  </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </div>
          <div class="d-flex align-center ma-0">

            <bulkChecklistUpload @reload="refreshData()" v-if="$nova.hasRight(right, 'add')" />
            <v-btn small height="4vh" color="black" class="mr-1 px-4 border-dark white--text" @click="
              addeditdialog = true;
            addeditdata = {};
            addeditdataerror = {};
            locationUserList = [];
            customassertions = false;
            " v-if="$nova.hasRight(right, 'add')">
              <v-icon class="pr-2">mdi-plus</v-icon>
              <span class="text-capitalize">New Task</span>
            </v-btn>
          </div>
        </div>
        <div class="d-flex align-center px-2 justify-space-between my-3 ">

          <lb-string placeholder="Search" label="" v-model="search" width="30vh" appendiconinner="mdi-magnify"
            hidedetails  radius="8px" :isClearable="false" />
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <div class="">


            <v-menu v-if="selectItem.length > 0" :close-on-content-click="false" offset-y
              transition="slide-y-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-tooltip left content-class="tooltip-left">
                  <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                  <v-btn icon v-bind="{ ...attrs, ...tooltipAttrs }" v-on="{ ...on, ...tooltipOn }"  class="ml-2"><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                  </template>
                  <span>Bulk actions</span>
                </v-tooltip>
              </template>

              <v-list style="max-height:50vh ;width:30vh; overflow:hidden; z-index:2"
                class="pa-0 ma-0 d-flex flex-column align-start justify-start ">
                <v-list-title class="ma-0  all-width">
                  <div class="d-flex grayBackground align-center  pa-3 justify-space-between">
                    <p class="ma-0 font-weight-bold" v-if="bulkActionStage === 0">Bulk Actions</p>
                    <div class="d-flex ">
                      <v-icon small v-if="bulkActionStage > 0" @click="bulkActionStage--" color="black"
                        class="px-2">mdi-keyboard-backspace</v-icon>
                      <p class="ma-0 font-weight-bold" v-if="bulkActionStage === 1">Choose {{$store.state.LocationHeader|| 'Location'}}</p>
                      <p class="ma-0 font-weight-bold" v-if="bulkActionStage === 2">Select Preparer</p>
                      <p class="ma-0 font-weight-bold" v-if="bulkActionStage === 3">Select Approver</p>
                    </div>
                    <v-icon small color="black" class="px-2">mdi-information-outline</v-icon>
                  </div>
                  <v-divider></v-divider>
                </v-list-title>
                <span v-if="bulkActionStage === 0" class="pa-2 ma-2 cursor-pointer" @click="bulkActionStage++">
                  {{$store.state.LocationHeader|| 'Location' }}
                  &
                  Users</span>

                <v-list-item v-else class="all-width d-flex px-1 flex-column" style="max-height:25vh; overflow:scroll">
                  <div class="ma-0 pa-0 my-1 all-width" v-if="bulkActionStage === 1">
                    <lb-string label="" placeholder="Search" v-model="searchlocation" :hidedetails="true"
                      appendinnericon="mdi-magnify" class="px-2 mt-1 all-width" />
                    <v-checkbox v-for="(item, index) in filteredlocation" :key="index" v-model="addLocation"
                      :value="item._id" color="caccounting" class="ma-0 pa-0 py-1" hide-details
                      :label="item.name"></v-checkbox>
                  </div>

                  <div class="ma-0 pa-0 my-1 all-width" v-else-if="bulkActionStage === 2">
                    <lb-string label="" placeholder="Search" v-model="searchusername" :hidedetails="true"
                      appendinnericon="mdi-magnify" class="px-2 mt-1 all-width" />
                    <v-checkbox v-for="(item, index) in filteredUsername" :key="index" v-model="addPrepares"
                      :value="item._id" color="caccounting" class="ma-0 pa-0 py-1" hide-details
                      :label="item.name"></v-checkbox>
                  </div>

                  <div class="ma-0 pa-0 my-1 all-width" v-else-if="bulkActionStage === 3">
                    <lb-string label="" placeholder="Search" v-model="searchusername" :hidedetails="true"
                      appendinnericon="mdi-magnify" class="px-2 mt-1 all-width" />
                    <v-checkbox v-for="(item, index) in filteredUsername" :key="index" v-model="addApprover"
                      :value="item._id" color="caccounting" class="ma-0 pa-0 py-1" hide-details
                      :label="item.name"></v-checkbox>
                  </div>
                </v-list-item>

                <v-divider class="all-width"></v-divider>

                <div v-if="bulkActionStage !== 0" class="d-flex all-width pa-1 align-center justify-space-between">
                  <v-btn v-if="bulkActionStage === 1 " color="black" class="white--text" @click="addnewLocation=true; locationdata=[]"> Add {{this.$store.state.LocationHeader|| 'Location'}}</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="black" class="white--text" @click="nextStage()"
                    v-if="bulkActionStage === 1 || bulkActionStage === 2">Next</v-btn>
                  <v-btn color="black" class="white--text" @click="submitChanges()" v-else-if="bulkActionStage === 3">Submit</v-btn>
                </div>
              </v-list>
            </v-menu>

            <v-tooltip bottom content-class="tooltip-bottom">
              <template v-slot:activator="{ on }">
                <v-btn class="shadow-off mr-2" v-on="on" text @click="exportToCsv()" small
                  icon><v-icon>mdi-file-excel-outline</v-icon></v-btn>
              </template>
              <span>Download Excel</span>
            </v-tooltip>

            <v-menu v-model="menuOpen" :close-on-content-click="false" offset-y transition="slide-y-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-tooltip left content-class="tooltip-left">
                  <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                  <v-btn icon v-bind="{ ...attrs, ...tooltipAttrs }" v-on="{ ...on, ...tooltipOn }"  class="ml-2 border-on"><v-icon color="caccounting">mdi-table-large-plus</v-icon></v-btn>
                  </template>
                  <span>Customize column</span>
                </v-tooltip>
              </template>

              <v-list style="max-height:50vh ; overflow:hidden; z-index:13 !important"
                class="pa-0 ma-0 d-flex flex-column align-start justify-start all-width">
                <v-list-title class="ma-0  all-width">
                  <div class="d-flex grayBackground align-center pa-2 pb-3 pt-3 pb-1 justify-space-between">
                    <p class="ma-0 font-weight-bold">Customize Column</p>
                  </div>
                  <v-divider></v-divider>
                  <lb-string label="" placeholder="Search" class="px-2 mt-3 mb-2" v-model="searchcolumn" width="30vh"
                    :clearable="false" appendiconinner="mdi-magnify" hidedetails radius="8px" />
                </v-list-title>


                <v-list-item style="max-height:25vh ; overflow:scroll"
                  class="d-flex all-width align-start pa-0 ma-0 flex-column">
                  <v-list-item v-for="(item, index) in filteredHeaders" class="d-flex pa-0 px-2 ma-0 " :key="index">
                    <v-checkbox color="caccounting" v-if="item.text !== 'Action'" :ripple="false" class="pa-0 ma-0"
                      @change="addTableHeader(item)" hide-details :input-value="item.visible"
                      :label="item.text"></v-checkbox>
                  </v-list-item>

                </v-list-item>

              </v-list>
            </v-menu>

          </div>

        </div>

        <div class="d-flex">
          <div v-for="(filter, index) in selectedFilterItems" :key="index" class="d-flex">
            <div v-for="(values, key) in filter" :key="key" class="d-flex">
              <div v-for="(value, i) in values" :key="i"
                class="d-flex border-on pa-1 radius-medium caccounting--text caccounting-background mx-2 mb-2">
                <p class="ma-0 pa-0 pr-1 text-capitalize">{{ key }} - </p>
                <p class="ma-0 pa-0">{{ value }}</p>
              </div>
            </div>
          </div>
          <v-btn v-if="selectedFilterItems.length > 0" text class="font-weight-bold text-caption"
            @click="resetStage">Reset
            filter
          </v-btn>
        </div>

        <lb-datatableaction :headers="headers" :tabledata="paginatedFilteredItems" :itemsperpage="itemsPerPage"
          :searchterm="search" @select-all-change="select" :isDataSelected="selectItem.length > 0 && selectItem.length < paginatedFilteredItems.length ? true : false">
          <template v-slot:tbody>
            <tr v-for="item in paginatedFilteredItems" :key="item._id" class="cursor-pointer "
              :class="{ 'low-opacity': item.status === false, '': viewchecklistdata === item }">
              <td class="  d-flex align-center justify-center">
                  <v-checkbox dense
                  color="caccounting"
                  class="ma-0 py-1 single-line"
                  :ripple="false"
                  hide-details
                  :value="item"
                  multiple
                  v-model="selectItem"></v-checkbox>
              </td>
              <template v-for="header in headers">
                <td v-if="header.visible" :key="header.value" 
                  :class="{ 'description-header': header.value === 'description', 'single-line': header.value !== 'description' }">
                  <span v-if="header.value === 'description'" @click="viewChecklist(item)" class="line-clamp-2 font-small"> {{
                    getItemData(item, header.value) }}</span>
                  <div v-else-if="header.value === 'assertionscount'">
                    <span class="pr-2 font-small">{{ item.assertions[0] }}</span>
                    <v-tooltip top content-class="tooltip-top">
                      <template v-slot:activator="{ on }">

                        <span v-on="on" v-if="item.assertions.length > 1"
                          class="border-on radius-mini text-caption purple--text pa-1 ">+{{ item.assertions.length
                            - 1 }}</span>

                      </template>
                      <div v-for="(item, index) in item.assertions" :key="index">
                        <span>{{item}}</span>
                      </div>
                    </v-tooltip>
                   
                  </div>
                  <div v-else-if="header.value === 'location'">
                    <span v-if="item.location.length > 0"
                      class="font-small caccounting--text caccounting-background pa-1 mr-2 radius-mini">{{
                        item.location[0] }}</span>


                        <v-tooltip top content-class="tooltip-top">
                          <template v-slot:activator="{ on }">
    
    
                                <span v-if="item.location.length > 1" v-on="on"
                                  class="border-on radius-mini text-caption purple--text pa-1 ">+{{ item.location.length
                                    - 1 }}</span>
                          </template>
                          <div v-for="(item, index) in item.location " :key="index">
                            <span>{{item}}</span>
                          </div>
                        </v-tooltip>
                        
                  </div>
                  <div v-else-if="header.value === 'tags'">
                    <span class="pr-2 font-small">{{ item.tags[0] }}</span>

                    <v-tooltip top content-class="tooltip-top">
                      <template v-slot:activator="{ on }">

                    <span v-if="item.tags.length > 1" v-on="on" class="border-on radius-mini text-caption purple--text pa-1 ">+{{
                      item.tags.length - 1
                      }}</span>

                      </template>
                      <div v-for="(item, index) in item.tags" :key="index">
                        <span>{{item}}</span>
                      </div>
                    </v-tooltip>
                  </div>
                  <div class="" v-else-if="header.value === 'approver'">
                   
                      <div class="d-flex" v-if="item.approver.length>0">
                        <div v-for="(approver, index) in item.approver" :key="index" class="avatars-group__item"
                        style="z-index:1 ; position:sticky">
                        <v-avatar v-on="on" size="25" color="caccounting"
                          class="white--text font-small border-on d-flex pa-0 avatar-white-border ma-0 v-avatar">
                          {{ approver[0] }}
                        </v-avatar>
                         
                        </div>
                      </div>
                  </div>
                  <div class="" v-else-if="header.value === 'preparer'">
                      <div class=d-flex v-if="item.preparer.length>0">
                        <div v-for="(preparer, index) in item.preparer" :key="index" class="avatars-group__item"
                          style="z-index:1 ; position:sticky">
                          <v-avatar v-on="on" size="25" color="caccounting"
                            class="white--text font-small border-on d-flex pa-0 avatar-white-border ma-0 v-avatar">
                            {{ preparer[0] }}
                          </v-avatar>
                         
                        </div>
                      </div>
                  </div>
                  <div class="" v-else-if="header.value === 'action'">

                <v-tooltip left content-class="tooltip-left">
                  <template v-slot:activator="{ on }">
                    <v-btn icon @click="addEditForm(item)" v-on="on"><v-icon>mdi-pencil-outline</v-icon></v-btn>
                  </template>
                  Edit
                </v-tooltip>

                <v-menu :close-on-content-click="false" offset-y transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-tooltip bottom content-class="tooltip-bottom">
                      <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                      <v-btn icon v-bind="{ ...attrs, ...tooltipAttrs }" v-on="{ ...on, ...tooltipOn }"  class="ml-2"><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                      </template>
                      <span>Action</span>
                    </v-tooltip>
                  </template>
  
                  <v-list class="pa-0 ma-0">
                    <v-menu :close-on-content-click="false" offset-x class="activitymenu"
                      transition="slide-x-transition" left style="min-width: fit-content !important">
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item class="pa-0 ma-0 px-3" v-bind="attrs" v-on="on">
                          <v-icon>mdi-history</v-icon>
                          <p class="pa-0 ma-0 px-2">Activity Log</p>
                        </v-list-item>
                      </template>
  
                      <v-list class="pa-0 ma-0 d-flex flex-column align-start justify-start all-width"
                        style="max-height:50vh; overflow:hidden">
                        <v-list-tilte
                          class="d-flex align-center mb-2 grayBackground black--text pa-2 justify-start all-width">
                          <p class="pa-0 ma-0">Activity Log</p>
                        </v-list-tilte>
                        <v-list-item class="d-flex flex-column all-width align-start justify-start"
                          style="overflow:scroll">
                          <div class="d-flex flex-column align-start justify-start all-width"
                            v-for="(v, k) in activityLogById" :key="k">
                            <span class="text-caption py-1"
                              v-if="k === 0 || v.created_at.split('T')[0] !== activityLogById[k - 1].created_at.split('T')[0]">
                              {{ $nova.formatDateTime(new Date(v.created_at), "DD MMM yyyy") }}
                              {{ v.created_at.split("T")[0] }}
                            </span>
                            <v-timeline class="pa-0 ma-0 all-width">
                              <v-timeline-item class="d-flex align-start justify-start all-width">
                                <template v-slot:icon>
                                  <v-avatar size="6" color="gray" class="custom-avatar  "></v-avatar>
                                </template>
                                <template v-slot:opposite>
                                  <span class="text-caption align-start d-flex ">
                                    {{ $nova.formatDateTime(new Date(v.created_at), "hh:mm A") }}
                                  </span>
                                </template>
                                <div class="d-flex flex-column align-start d-flex">
                                  <span class="text-caption">
                                    {{ v.text || (activitylogconfig[v.type] || {}).displaytext || v.type }}
                                  </span>
                                  <div class="d-flex align-center mr-10 justify-center">
                                    <v-avatar class="mr-2" size="20">{{ (v.userid_detail ||
                                      {}).name[0] }}</v-avatar>
                                    <span>{{ (v.userid_detail || {}).name }}</span>
                                  </div>
                                </div>
                              </v-timeline-item>

                            </v-timeline>
                          </div>
                        </v-list-item>
                      </v-list>
                    </v-menu>

                    <v-list-item @click="enableDisableAccess(item._id, 'disable')" v-if="item.status"
                      class="pa-0 ma-0 px-3">
                      <v-icon color="error">mdi-close-circle</v-icon>
                      <p class="error--text pa-0 ma-0 px-2">Disable</p>
                    </v-list-item>
                    <v-list-item @click.stop="enableDisableAccess(item._id, 'enable')" v-else class="pa-0 ma-0 px-3">
                      <v-icon color="success">mdi-check-circle</v-icon>
                      <p class="success--text pa-0 ma-0 px-2">Enable</p>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <span class="line-clamp-2 font-small" v-else> {{ getItemData(item, header.value) }}</span>
              </td>
              </template>

            </tr>
          </template>
        </lb-datatableaction>
        <Pagination :list="filteredItemsfromsearch" :itemsperpage="itemsPerPage" @paginated-data="setPaginatedData" />

      </v-card-text>
    </v-card>

    <lb-drawer v-model="viewchecklistdialog" heading="Task Details" width="800">
      <template v-slot:body>

        <div  class="d-flex body-1 mb-4">
          <span class=" radius-mini text-body-2 purple--text pa-2 mr-3  font-weight-bold"
            style=" height:30px; background:#F4F4FB">{{ viewchecklistdata.ref }}</span>

          <div class="black--text text-subtitle-1 font-weight-bold">{{ viewchecklistdata.description || "" }}</div>
        </div>
        <div :style="{ height: `${fixedDivHeight}px` }"></div>
        <div class="">
          <div style="height:4.5vh" class="d-flex">
            <p class="ml-1">Due Days</p>
            <p class="">
              <span class="dueDate ml-2 my-3" style="margin-left:47px !important">
                <v-icon color="caccounting" class="pr-1">mdi-calendar-blank</v-icon>
                {{ viewchecklistdata.duedays || 'No data' }} Days
              </span>
            </p>
          </div>
          <v-table>

            <template>
              <tbody class="ma-3">
                <v-slide-y-transition>
                  <div v-if="showMore">
                    <tr style="height:4.5vh">
                      <td class="ml-3">Category</td>
                      <td class="">
                        <span class="ml-2 radius-small text-caption pa-2 black--text thead-color"
                          style="margin-left:30px !important">
                          {{ viewchecklistdata.category || 'No data' }}
                        </span>
                      </td>
                    </tr>
                    <tr style="height:4.5vh">
                      <td class="ml-3">Sub Category</td>
                      <td class="">
                        <span class="ml-2 radius-small text-caption pa-2 black--text thead-color"
                          style="margin-left:30px !important">
                          {{ viewchecklistdata.subcategory || 'No data' }}
                        </span>
                      </td>
                    </tr>
                    <tr style="height:4.5vh">
                      <td class="ml-3">Assertions</td>
                      <td style="margin-left:30px !important" class="d-flex">
                        <template v-if="viewchecklistdata.assertions.length > 0">
                          <p v-for="(item, index) in viewchecklistdata.assertions" :key="index"
                            class="light-purple-background radius-mini text-caption purple--text pa-1 mr-2">
                            {{ item }}
                          </p>
                        </template>
                        <template v-else>
                          <p class="text-caption pt-2 pa-1 mr-2 black--text mx-1">No data</p>
                        </template>
                      </td>
                    </tr>
                    <tr style="height:4.5vh">
                      <td class="ml-3">Tags</td>
                      <td style="margin-left:32px !important" class="d-flex">
                        <template v-if="viewchecklistdata.tags.length > 0">
                          <p v-for="(item, index) in viewchecklistdata.tags" :key="index"
                            class="light-purple-background radius-mini text-caption purple--text pa-1 mr-2">
                            {{ item }}
                          </p>
                        </template>
                        <template v-else>
                          <p class="text-caption pt-2 pa-1 mr-2 black--text mx-1">No data</p>
                        </template>
                      </td>
                    </tr>
                    <tr style="height:4.5vh">
                      <td class="ml-3">Purpose</td>
                      <td class="">
                        <span class="ml-2 radius-small text-caption pa-2 black--text"
                          style="margin-left:30px !important">
                          {{ viewchecklistdata.purpose || 'No data' }}
                        </span>
                      </td>
                    </tr>
                    <tr style="height:4.5vh">
                      <td class="ml-3">Instruction</td>
                      <td class="">
                        <span class="ml-2 radius-small text-caption pa-2 black--text"
                          style="margin-left:30px !important">
                          {{ viewchecklistdata.instruction || 'No data' }}
                        </span>
                      </td>
                    </tr>
                  </div>
                </v-slide-y-transition>



                <tr>
                  <td>
                    <v-btn small @click="showMore = !showMore" color="white" class="pa-0 ">
                      <v-icon>{{ showMore ? 'mdi-chevron-up' : 'mdi-chevron-down' }} </v-icon>
                      <p class="ma-0 pa-0 text-button px-1 black--text ">
                        {{ showMore ? 'Hide items' : '6 more itmes' }}
                      </p>
                    </v-btn>
                  </td>
                </tr>
              </tbody>

            </template>

          </v-table>
          <v-row class="pb-2">
            <v-col cols="12" class="mt-4 py-0">
              <div class="body-2 font-weight-bold black--text pt-3 pb-1">Location & Users</div>

              <v-row class="my-2 d-flex align-center ">
                <v-row class="my-2 d-flex align-center" v-if="viewchecklistdata.locationlist">
                  <v-col cols="4" v-for="(item, index) in displayedLocations" :key="index">
                    <v-col class="d-flex flex-column pa-0 ma-0 radius-small border-on mx-1">
                      <div class="d-flex align-center py-1 grayBackground justify-space-between"
                        style="border-radius:8px 8px 0px 0px">
                        <p class="ma-0 pa-0 px-2 text-subtitle-1 black--text"> {{ getLocationName(item.location_id ||
                          "") }}
                        </p>
                      </div>
                      <div class="d-flex pa-2">
                        <p class="ma-0 pa-0 pr-3">Preparer</p>
                        <div class="d-flex all-width">
                          <div class="d-flex ">
                            <div v-for="(preparer, index) in item.preparer.slice(0, 3)" :key="index"
                              class="avatars-group__item ">
                              <v-tooltip top content-class="tooltip-top">
                                <template v-slot:activator="{ on }">
                                  <v-avatar v-on="on" size="27" color="caccounting"
                                    class="white--text border-on d-flex pa-0 ma-0 v-avatar">
                                    {{ getPreparesName(preparer).split(' ').slice(0, 2).map(word =>
                                      word[0].toUpperCase()).join('') }}
                                  </v-avatar>
                                </template>
                                <span>{{ getPreparesName(preparer) }}</span>
                              </v-tooltip>
                            </div>
                            <v-tooltip v-if="item.preparer.length > 3 && item.preparer.length < 8" top
                              content-class="tooltip-top">
                              <template v-slot:activator="{ on, attrs }">
                                <v-avatar v-bind="attrs" v-on="on" size="25" color="lightpurple"
                                  style="margin-left:-6px !important"
                                  class="caccounting--text d-flex align-center justify-center pa-0 ma-0">
                                  <v-icon color="caccounting">mdi-plus</v-icon>
                                </v-avatar>
                              </template>
                              <div class="" v-for="(name, index) in item.preparer.slice(3)" :key=index>
                                <span>{{ getPreparesName(name) }}</span>
                              </div>
                            </v-tooltip>

                            <v-menu v-else-if="item.preparer.length >= 8" :close-on-content-click="false" offset-y
                              color="white" class="pa-0 ma-0" style="background-color:white">
                              <template v-slot:activator="{ on, attrs }">
                                <v-avatar size="25" color="lightpurple"
                                  class="caccounting--text d-flex align-center justify-center pa-0 ma-0" v-bind="attrs"
                                  v-on="on" style="margin-left:-6px !important">
                                  <v-icon color="caccounting">mdi-plus</v-icon>
                                </v-avatar>
                              </template>
                              <v-list class="overflow-scroll radius-small pa-0 pt-2 mb-0 "
                                style="min-width:18vh; max-height:20vh">
                                <div class="overflow-scroll px-2 userMenu">
                                  <v-list-item v-for="(item, index) in item.preparer.slice(3)" :key="index"
                                    style="background-color:white" class="pa-0">
                                    <v-list-item-content class="pa-0 ma-0 ">
                                      <span> {{ getPreparesName(preparer) }}</span>
                                    </v-list-item-content>
                                  </v-list-item>
                                </div>
                              </v-list>
                            </v-menu>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex pa-2">
                        <p class="ma-0 pa-0 pr-3">Approver</p>
                        <div class="d-flex all-width">
                          <div class="d-flex ">
                            <div v-for="(approver, index) in item.approver.slice(0, 3)" :key="index"
                              class="avatars-group__item ">
                              <v-tooltip top content-class="tooltip-top">
                                <template v-slot:activator="{ on }">
                                  <v-avatar v-on="on" size="27" color="caccounting"
                                    class="white--text border-on d-flex pa-1 ma-0 v-avatar">
                                    {{ getApproversName(approver).split(' ').slice(0, 2).map(word =>
                                      word[0].toUpperCase()).join('') }}
                                  </v-avatar>
                                </template>
                                <span>{{ getApproversName(approver) }}</span>
                              </v-tooltip>
                            </div>
                            <v-tooltip v-if="item.approver.length > 3 && item.approver.length < 8" top
                              content-class="tooltip-top">
                              <template v-slot:activator="{ on, attrs }">
                                <v-avatar v-bind="attrs" v-on="on" size="25" color="lightpurple"
                                  style="margin-left:-6px !important"
                                  class="caccounting--text d-flex align-center justify-center pa-0 ma-0">
                                  <v-icon color="caccounting">mdi-plus</v-icon>
                                </v-avatar>
                              </template>
                              <div class="" v-for="(name, index) in item.approver.slice(3)" :key=index>
                                <span>{{ getApproversName(name) }}</span>
                              </div>
                            </v-tooltip>

                            <v-menu v-else-if="item.approver.length >= 8" :close-on-content-click="false" offset-y
                              color="white" class="pa-0 ma-0" style="background-color:white">
                              <template v-slot:activator="{ on, attrs }">
                                <v-avatar size="25" color="lightpurple"
                                  class="caccounting--text d-flex align-center justify-center pa-0 ma-0" v-bind="attrs"
                                  v-on="on" style="margin-left:-6px !important">
                                  <v-icon color="caccounting">mdi-plus</v-icon>
                                </v-avatar>
                              </template>
                              <v-list class="overflow-scroll radius-small pa-0 pt-2 mb-0 "
                                style="min-width:18vh; max-height:20vh">
                                <div class=" px-2 userMenu">
                                  <v-list-item v-for="(item, index) in item.approver.slice(3)" :key="index"
                                    style="background-color:white" class="pa-0">
                                    <v-list-item-content class="pa-0 ma-0">
                                      <span> {{ getApproversName(item) }}</span>
                                    </v-list-item-content>
                                  </v-list-item>
                                </div>
                              </v-list>
                            </v-menu>

                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center" v-if="viewchecklistdata.locationlist.length > 6">
                    <v-btn @click="showAll = !showAll" class=" d-flex align-center justify-center pa-0 px-1  border-on"
                      style="background:white; ">
                      <v-icon class="pr-1">{{ showAll ? 'mdi-chevron-up' : 'mdi-chevron-down' }} </v-icon>
                      <p class="ma-0 pa-0" v-if="!showAll">{{ viewchecklistdata.locationlist.length - 6 }} more
                        locations
                      </p>
                      <p class="ma-0 pa-0" v-else>Show Less</p>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="4" class="mx-3">
                    No data
                  </v-col>
                </v-row>
              </v-row>
            </v-col>
            <v-col cols="12" class="mt-2 py-0">
              <div class="d-flex align-center">
                <div class="body-2 font-weight-bold py-2 black--text">Linked Gl's</div>
                <v-spacer></v-spacer>
              </div>
            </v-col>
            <v-col cols="12" class=" radius-small py-0">
              <v-simple-table class="border-on radius-small"
                v-if="viewchecklistdata.glcode !== undefined && viewchecklistdata.glcode?.length !== 0">
                <template>
                  <thead class="border-bottom ">
                    <th class="thead-color text-start px-2 py-2">
                      Gl Code
                    </th>
                    <th class="thead-color text-start px-2 py-2">
                      Name
                    </th>
                    <th class="thead-color text-start px-2 py-2">
                      Category
                    </th>
                    <th class="thead-color text-start px-2 py-2">
                      Sub Category
                    </th>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in viewchecklistdata.glcode" :key="index">
                      <td class="text-start px-2">{{ item.glcode }}</td>
                      <td class="text-start px-2">{{ item.name }}</td>
                      <td class="text-start px-2">{{ item.category }}</td>
                      <td class="text-start px-2">{{ item.subcategory }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <p v-else>
                No Data
              </p>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:actions>
        <v-row class="my-1 px-2 d-flex align-center justify-center">
          <p class="ma-0">*Required</p>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="addEditForm(viewchecklistdata)" class="white--text">Edit Task</v-btn>
        </v-row>
      </template>
    </lb-drawer>

    <lb-drawer v-model="addeditdialog" :heading="addeditdata._id ? 'Edit Task' : 'Create New Task'" width="600"
      :loading="addeditloading">
      <template v-slot:body>
        <div>
          <v-row>
            <v-col cols="12" class="my-1 py-1">
              <p class="pa-0 ma-0  text-subtitle-1 mb-1">Task Description*</p>
              <lb-textBox v-model="addeditdata.description" label="" requriedrows="3" :hidedetails="true"
                :isRequired="true" :error="addeditdataerror.description" />
            </v-col>
            <v-col cols="6" class="my-1 py-1">
              <p class="pa-0 ma-0  text-subtitle-1">Category*</p>
              <lb-tagsInput :multiple="false" :multiplechips="false" v-model="addeditdata.category"
                appendiconinner="mdi-chevron-down" :hidedetails="true" :error="addeditdataerror.category"
                :items="categoryAcc" />
            </v-col>
            <v-col cols="6" class="my-1 py-1">
              <p class="pa-0 ma-0  text-subtitle-1">Sub Category*</p>
              <lb-tagsInput :multiple="false" :multiplechips="false" v-model="addeditdata.subcategory"
                :hidedetails="true" :error="addeditdataerror.subcategory" :items="subcategoryAcc" appendiconinner="mdi-chevron-down"/>
            </v-col>
            <v-col cols="6" class="my-1 py-1">
              <p class="pa-0 ma-0  text-subtitle-1">Add Assertions</p>
              <lb-tagsInput v-model="addeditdata.assertions" :hidedetails="true" :items="possibleassertions" label=""
                :error="addeditdataerror.assertions" tagcolor="purpleborder" :multiple="true" :multiplechips="true" appendiconinner="mdi-chevron-down" />
            </v-col>
            <v-col cols="6" class="my-1 py-1">
              <p class="pa-0 ma-0  text-subtitle-1">Tags*</p>
              <lb-tagsInput tagcolor="purpleborder" :multiple="true" v-model="addeditdata.tags" :multiplechips="true"
                :hidedetails="true" :items="Alltags" :error="addeditdataerror.tags" appendiconinner="mdi-chevron-down"/>
            </v-col>
            <v-col cols="6" class="my-1 py-1">
              <p class="ma-0 pa-0  text-subtitle-1">Purpose</p>
              <lb-textBox v-model="addeditdata.purpose" label="" height='100' :hidedetails="true" requriedrows="3"
                :error="addeditdataerror.purpose" />
            </v-col>
            <v-col cols="6" class="my-1 py-1">
              <p class="ma-0 pa-0  text-subtitle-1"> Instruction</p>
              <lb-textBox v-model="addeditdata.instruction" label="" :error="addeditdataerror.instruction" height="100" requriedrows="3"
                :hidedetails="true" />
            </v-col>
            <v-col cols="6" class="my-1 mb-3 py-1">
              <p class="ma-0 pa-0  text-subtitle-1">Due Date</p>
              <lb-number placeholder="Enter value between 1 and 200" label="" type="number" min="-200" max="200"
                v-model="addeditdata.duedays" :error="addeditdataerror.duedays" hidedetails/>
              <span class="text-caption gray--text">Enter the number of days to set the due date automatically based on the cycle ending date.</span>
            </v-col>
            <v-col cols="6" class="my-1 py-1">
              <p class="ma-0 pa-0  text-subtitle-1">Priority</p>
              <lb-dropdown v-model="addeditdata.priority" :items="priorityList" :error="addeditdataerror.priority"
                label="" />
            </v-col>
          </v-row>
          <p class="pa-0 ma-0 mb-1  text-subtitle-1 mx-1">{{$store.state.LocationHeader|| 'Location'}} and Users*</p>
          <v-row class="mb-3 d-flex align-center " v-if="locationUserList.length > 0">

            <v-col cols="4" v-for="(item, index) in locationUserList.slice(0, visibleRowsitems)" :key="index">
              <v-col class="d-flex flex-column pa-0 ma-0 radius-small border-on mx-1">
                <div class="d-flex align-center py-1 grayBackground justify-space-between"
                  style="border-radius:8px 8px 0px 0px">
                  <p class="ma-0 pa-0 px-2 text-subtitle-1 black--text">{{ getlocationNamebyId(item.location_id) }}</p>
                  <v-btn icon small @click="deleteLocation(item.location_id)">
                    <v-icon color="error">mdi-delete</v-icon>
                  </v-btn>
                </div>
                <lb-profilestack :items="item.preparer" :v-model="addPrepares" :updateFunction="addEditItem"
                  heading="Preparer" :allUser="formdata.users" />
                <lb-profilestack :items="item.approver" :v-model="addApprover" :updateFunction="addEditItem"
                  heading="Approver" :allUser="formdata.users" />
              </v-col>
            </v-col>

            <v-col class="mx-4  my-0 py-1 d-flex align-center justify-center all-width">
              <div class="">
                <v-menu v-model="menu" :close-on-content-click="false" offset-y transition="slide-y-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="white all-height blue--text text-caption" v-bind="attrs" v-on="on">
                      <v-icon small class="px-2">mdi-plus-circle-outline</v-icon>
                      <p class="ma-0 pa-0"> Add New</p>
                    </v-btn>
                  </template>

                  <v-list style="max-height:30vh ; overflow:hidden" class="pa-0 mas-0">
                    <v-list-title class="font-weight-bold text-subtitle px-4 py-2 d-flex justify-space-between"
                      v-if="locationUserDropdown === 0">
                      <p class="ma-1 pa-0">Choose {{$store.state.LocationHeader|| 'Location'}}</p>
                      <v-icon>mdi-information-outline</v-icon>
                    </v-list-title>
                    <v-list-title class="font-weight-bold text-subtitle px-4 py-2 d-flex justify-space-between"
                      v-if="locationUserDropdown === 1">
                      <p class="ma-1 pa-0">Select Preparer</p>
                      <v-icon>mdi-information-outline</v-icon>
                    </v-list-title>
                    <v-list-title class="font-weight-bold text-subtitle px-4 py-2 d-flex justify-space-between"
                      v-if="locationUserDropdown === 2">
                      <p class="ma-1 pa-0">Select Approver </p>
                      <v-icon>mdi-information-outline</v-icon>
                    </v-list-title>

                    <v-divider></v-divider>
                    <v-list-item style="max-height:20vh ; overflow:scroll"
                      class="d-flex align-start pa-0 ma-0 flex-column" v-if="locationUserDropdown === 0">
                      <v-list-item v-for="(item, index) in filteredUniqueLocations" class="d-flex " :key="index">
                        <v-checkbox color="caccounting" class="ma-0 pa-0" hide-details :label="item.name"
                          v-model="addLocation" :value="item"></v-checkbox>
                      </v-list-item>

                    </v-list-item>
                    <v-list-item style="max-height:20vh ; overflow:scroll"
                      class="d-flex align-start pa-0 ma-0 flex-column px-1" v-if="locationUserDropdown === 1">
                      <lb-string label="" placeholder="search" append-icon="mdi-magnify" class="mt-1 all-width" hidedetails v-model="searchusername"/>
                      <v-list-item v-for="(item, index) in filteredUsername"  class="d-flex px-0" :key="index">
                        <v-checkbox color="caccounting" class="ma-0 pa-0" multiple hide-details :label="item.name"
                          v-model="addPrepares" :value="item._id"></v-checkbox>
                      </v-list-item>

                    </v-list-item>
                    <v-list-item style="max-height:20vh ; overflow:scroll"
                      class="d-flex align-start pa-0 ma-0 flex-column" v-if="locationUserDropdown === 2">
                      <lb-string label="" placeholder="search" append-icon="mdi-magnify" class="mt-1 all-width" hidedetails v-model="searchusername"/>
                      <v-list-item v-for="(item, index) in filteredUsername" class="d-flex px-0" :key="index">
                        <v-checkbox color="caccounting" class="ma-0 pa-0" hide-details multiple :label="item.name"
                         v-model="addApprover" :value="item._id"></v-checkbox>
                      </v-list-item>

                    </v-list-item>
                    <v-divider></v-divider>
                    <div class="d-flex justify-space-between">
                      <v-spacer></v-spacer>
                      <v-btn small color="primary" @click="nextStepforLocation(++locationUserDropdown)"
                        v-if="locationUserDropdown < 2" class="ma-1">Next</v-btn>
                      <v-btn small color="primary" class="ma-1" v-if="locationUserDropdown === 2"
                        @click="addUserLocation(addLocation, addPrepares, addApprover);locationUserDropdown=0">Submit</v-btn>
                    </div>
                  </v-list>
                </v-menu>



              </div>
            </v-col>

          </v-row>

          <v-row v-else>
            <v-col class="mx-4 dashed_border mt-3 my-0 py-1 d-flex align-center justify-center all-width">
              <div class="">
                <v-menu v-model="menu" :close-on-content-click="false" offset-y transition="slide-y-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="white all-height blue--text text-caption" v-bind="attrs" v-on="on">
                      <v-icon small class="px-2">mdi-plus-circle-outline</v-icon>
                      <p class="ma-0 pa-0">Add {{$store.state.LocationHeader|| 'Location' }} and users</p>
                    </v-btn>
                  </template>

                  <v-list style="max-height:30vh ; overflow:hidden" class="pa-0 mas-0">
                    <v-list-title class="font-weight-bold text-subtitle px-4 py-2 d-flex justify-space-between"
                      v-if="locationUserDropdown === 0">
                      <p class="ma-1 pa-0">Choose {{$store.state.LocationHeader|| 'Location'}}</p>
                      <v-icon>mdi-information-outline</v-icon>
                    </v-list-title>
                    <v-list-title class="font-weight-bold text-subtitle px-4 py-2 d-flex justify-space-between"
                      v-if="locationUserDropdown === 1">
                      <p class="ma-1 pa-0">Select Preparer</p>
                      <v-icon>mdi-information-outline</v-icon>
                    </v-list-title>
                    <v-list-title class="font-weight-bold text-subtitle px-4 py-2 d-flex justify-space-between"
                      v-if="locationUserDropdown === 2">
                      <p class="ma-1 pa-0">Select Approver </p>
                      <v-icon>mdi-information-outline</v-icon>
                    </v-list-title>

                    <v-divider></v-divider>
                    <v-list-item style="max-height:20vh ; overflow:scroll"
                      class="d-flex align-start pa-0 ma-0 flex-column" v-if="locationUserDropdown === 0">
                      <v-list-item v-for="(item, index) in filteredUniqueLocations" class="d-flex " :key="index">
                        <v-checkbox color="caccounting" class="ma-0 pa-0" hide-details :label="item.name"
                          v-model="addLocation" :value="item"></v-checkbox>
                      </v-list-item>

                    </v-list-item>
                    <v-list-item style="max-height:20vh ; overflow:scroll"
                      class="d-flex align-start pa-0 ma-0 flex-column px-1" v-if="locationUserDropdown === 1">
                       <lb-string label="" placeholder="search" append-icon="mdi-magnify" class="mt-1 all-width" hidedetails v-model="searchusername"/>
                      <v-list-item v-for="(item, index) in filteredUsername" class="d-flex px-0" :key="index">
                        <v-checkbox color="caccounting" class="ma-0 pa-0" multiple hide-details :label="item.name"
                          v-model="addPrepares" :value="item._id"></v-checkbox>
                      </v-list-item>

                    </v-list-item>
                    <v-list-item style="max-height:20vh ; overflow:scroll"
                      class="d-flex align-start pa-0 ma-0 flex-column px-1" v-if="locationUserDropdown === 2">
                      <lb-string label="" placeholder="search" append-icon="mdi-magnify" class="mt-1 all-width" hidedetails v-model="searchusername"/>
                      <v-list-item v-for="(item, index) in filteredUsername" class="d-flex px-0" :key="index">
                        <v-checkbox color="caccounting" class="ma-0 pa-0" hide-details multiple :label="item.name"
                         v-model="addApprover" :value="item._id"></v-checkbox>
                      </v-list-item>

                    </v-list-item>
                    <v-divider></v-divider>
                    <div class="d-flex justify-space-between">
                      <v-spacer></v-spacer>
                      <v-btn small color="black" @click="nextStepforLocation(++locationUserDropdown)"
                        v-if="locationUserDropdown < 2" class="ma-1 white--text">Next</v-btn>
                      <v-btn small color="black" class="ma-1 white--text" v-if="locationUserDropdown === 2"
                        @click="addUserLocation(addLocation, addPrepares, addApprover),locationUserDropdown=0">Submit</v-btn>
                    </div>
                  </v-list>
                </v-menu>

              </div>
            </v-col>
          </v-row>


          <v-row class="mx-auto all-width align-center justify-center mt-2">
            <v-btn class=" d-flex align-center justify-center pa-0 px-1  border-on" style="background:white; "
              v-if="locationUserList.length > 6 && visibleRowsitems < locationUserList.length" @click="showMoreRows">
              See {{ locationUserList.length - visibleRowsitems }} More items
              <v-icon class="pr-1">mdi-chevron-down </v-icon>
            </v-btn>
            <v-btn class=" d-flex align-center justify-center pa-0 px-1  border-on" style="background:white; "
              v-if="visibleRowsitems > 6" @click="showLessRows">
              See Less
              <v-icon class="pr-1">mdi-chevron-up</v-icon>
            </v-btn>
          </v-row>
          <v-row>
            <v-col cols="12" class="my-0 mt-3 py-">
              <p class="ma-0 py-2 pa-0">Account No</p>
              <lb-dropdown v-model="addeditdata.glcode" :items="possibleGlCodes" multiple label=""
                :error="addeditdataerror.glcode" />
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:actions>
        <v-row class="my-1 px-2 d-flex align-center justify-center">
          <p class="ma-0">*Required</p>
          <v-spacer></v-spacer>
          <v-btn color="black" v-if="addeditdata._id" class="white--text" @click="addEditItem()">Update</v-btn>
          <v-btn color="black" v-else class="white--text" @click="addEditItem()">Create Task</v-btn>
        </v-row>
      </template>
    </lb-drawer>


    <!-- :heading="'Add New '" -->

    <lb-drawer
    v-model="addnewLocation"
    :heading="'Add New ' + (this.$store.state.LocationHeader || 'Location')"
    width="600"
    :loading="addnewLocationloading"
  >
    <template v-slot:body>
      <div>
        <v-row>
          <v-col cols="12" class="my-0 py-0">
            <p class="ma-0 pa-0  text-subtitle-1">Name*</p>
            <lb-string
              v-model="locationdata.name"
              label=""
              placeholder="Enter name "
              :error="locationdataerror.name"
            />
          </v-col>

          <v-col cols="12" class="my-0 py-0">
            <p class="ma-0 pa-0  text-subtitle-1">Description*</p>
            <lb-string
              v-model="locationdata.description"
              label=""
              placeholder="Enter description"
              :error="locationdataerror.description"
            />
          </v-col>

          <v-col cols="12" class="my-0 py-0">
            <p class="ma-0 pa-0  text-subtitle-1">Country</p>
            <lb-string
              v-model="locationdata.country"
              label=""
              placeholder="Enter Country"
              :error="locationdataerror.country"
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <p class="ma-0 pa-0  text-subtitle-1">Type</p>
            <lb-dropdown
              v-model="locationdata.type"
              :items="locationtypeItem"
              label=""
              placeholder="Enter Type"
              :error="locationdataerror.type"
              itemtext="name"
              itemvalue="value"
            />
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn small color="black"  @click="addLocationFun()"
      class="white--text">
        Add
      </v-btn>
    </template>
  </lb-drawer>


  </div>
</template>

<script>
import bulkChecklistUpload from "../../views/Components/BulkUploadChecklist.vue";
import Pagination from "../../../../views/common/Pagination.vue";
import XLSX from "xlsx-color";
export default {
  name: "financialclose_master_checklist",
  // props: ['props'],
  data: function () {
    return {
      right: "financialclose_master_checklist",
      // generateddescription: "",
      possibleassertions: [
        "Existence",
        "Occurrence",
        "Rights and obligations",
        "Completeness",
        "Accuracy valuation and allocation",
        "Cut–off",
        "Classification",
        "Presentation",
        "Others",
      ],
      addeditdata: {},
      addeditdataerror: {},
      addeditdialog: false,
      addeditloading: false,
      loading: false,
      formdata: {},
      userlist: {},
      listdata: [],
      bulkactionuserdialog: false,
      multiselectdata: [],
      bulkactionuserselected: false,
      bulkactionuserusertype: "",
      bulkactionuserid: "",
      menuOpen: false,
      headers: [
        {
          value: "ref",
          text: "Ref",
          visible: true,
          filterable: true,
          sortable: true,

        },
        {
          value: "description",
          text: "Description",
          visible: true,
          filterable: false,
          sortable: false,

        },
        {
          value: "category",
          text: "Category",
          visible: true,
          filterable: true,
          sortable: true,
        },
        {
          value: "subcategory",
          text: "Sub Category",
          visible: true,
          filterable: true,
          sortable: true,
        },
        {
          value: "assertionscount",
          text: "Assertions",
          visible: true,
          filterable: false,
          sortable: false,
        },
        {
          value: "location",
          text: this.$store.state.LocationHeader|| 'Location',
          visible: true,
          filterable: true,
          sortable: true,
        },
        {
          value: "duedays",
          text: "Due Days",
          visible: true,
          filterable: false,
          sortable: true,
        },
        {
          value: "priority",
          text: "Priority",
          visible: true,
          filterable: true,
          sortable: true,
        },
        {
          value: "purpose",
          text: "Purpose",
          visible: true,
          filterable: false,
          sortable: false,
        },
        {
          value: "instruction",
          text: "Instruction",
          visible: true,
          filterable: false,
          sortable: false,
        },
        {
          value: "tags",
          text: "Tags",
          visible: true,
          filterable: true,
          sortable: true,
        },
        {
          value: "created_at",
          text: "Created On",
          visible: true,
          filterable: true,
          sortable: true,
        },
        {
          value: "preparer",
          text: "Preparer",
          visible: true,
          filterable: true,
          sortable: false,
        },
        {
          value: "approver",
          text: "Approver",
          visible: true,
          filterable: true,
          sortable: false,
        },
        {
          value: "action",
          text: "Action",
          visible: true,
          sticky: true,
        },

      ],
      formatedData: [],
      possibleusers: [],
      bulkactionuserloading: false,
      selectaction: "add",
      changetouser: "",
      itemapprover: [
        { value: "associate", name: "Add" },
        { value: "remove", name: "Remove" },
        { value: "replace", name: "Replace" },
        { value: "move", name: "Change to Approver" },
      ],
      itempreparer: [
        { value: "associate", name: "Add" },
        { value: "remove", name: "Remove" },
        { value: "replace", name: "Replace" },
        { value: "move", name: "Change to Preparer" },
      ],
      activitylogconfig: {
        userassociate: {
          icon: "mdi-plus",
          color: "info",
          displaytext: "User Associated",
        },
        userreplaced: {
          icon: "mdi-account-convert",
          color: "info",
          displaytext: "User Replaced",
        },
        userremoved: {
          icon: "mdi-close",
          color: "error",
          displaytext: "User Removed",
        },
        usermoved: { icon: "mdi-sync", color: "", displaytext: "Updated" },
        tasklistupdated: {
          icon: "mdi-pencil",
          color: "primary",
          displaytext: "Updated",
        },
      },
      search: "",
      searchcolumn: "",
      searchlocation: "",
      searchusername: "",
      viewchecklistdialog: false,
      viewchecklistdata: {},
      userid: "",
      addnewLocation: false,
      addnewLocationloading: false,
      locationdialog: false,
      locationdialogloading: false,
      location_details: [],
      users_details: [],
      adduserdialog: false,
      addApprover: [],
      addPrepares: [],
      addLocation: [],
      locationUserList: [],
      locationdata: {},
      locationdataerror: {},
      addLocationerr: {},
      subcategoryAcc: [],
      categoryAcc: [],
      possibleGlCodes: [],
      locationBasedGl: [],
      addUsersAppPer: false,
      addpreparerusers: [],
      addapproverusers: [],
      typeAppPer: '',
      addAppPerLoa: '',
      itemsPerPage: 13,
      storeTotal: '',
      selectAll: false,
      selectItem: [],
      customassertions: [],
      locationtypeItem: [],
      tab: '',
      sorting: false,
      menu: false,
      Alltags: ["Yearly", "Half-yearly", "Monthly"],
      locationUserDropdown: 0,
      visibleRowsitems: 6,
      bulkActionStage: 0,
      activityLogById: [],
      showMore: false,
      showAll: false,
      priorityList: ["High", "Medium", "Low"],
      selectedFilterItems: [],
      searchfilters: '',
      fixedDivHeight: 0,
      paginatedFilteredItems:[]
    };
  },
  created() {
    this.init();
    this.processCOAData();
  },
  activated() {
    this.init();
  },
  components: {
    bulkChecklistUpload, Pagination
  },
  computed: {
    displayedLocations() {
      return this.showAll ? this.viewchecklistdata.locationlist : this.viewchecklistdata.locationlist.slice(0, 6);
    },
    filteredUniqueLocations() {
      const locationIds = new Set(this.locationUserList.map(item => item.location_id));
      const filteredLocationDetails = this.location_details.filter(
        location => !locationIds.has(location._id)
      );

      return filteredLocationDetails;
    },

    filteredItemsfromsearch() {
      let filteredData = this.formatedData.filter(item =>
        item.description.toLowerCase().includes(this.search.toLowerCase())
      );

      if (this.selectedFilterItems.length > 0) {
        filteredData = filteredData.filter(item => {
          return this.selectedFilterItems.every(filter => {
            return Object.entries(filter).every(([key, values]) => {
              const itemValue = item[key];
              return Array.isArray(itemValue)
                ? itemValue.some(val => values.includes(val))
                : values.includes(itemValue);
            });
          });
        });
      }

      return filteredData;
    },
    filteredHeaders() {
      const searchTerm = this.searchcolumn.toLowerCase();
      return this.headers.filter(header =>
        header.text.toLowerCase()?.includes(searchTerm)
      );
    },
    filteredlocation() {
      const searchTerm = this.searchlocation.toLowerCase();
      return this.location_details.filter(location =>
        location.name.toLowerCase()?.includes(searchTerm)
      );
    },
    filteredUsername() {
      const searchTerm = this.searchusername.toLowerCase();
      return this.formdata.users.filter(users =>
        users.name.toLowerCase()?.includes(searchTerm)
      );
    },
  },
  methods: {
    setPaginatedData(paginatedData) {
      this.paginatedFilteredItems = paginatedData; 
    },
    addPreparerToLocation(item) {
      this.addPrepares = [...this.addPrepares, item._id]
    },
    addApproverToLocation(item) {
      this.addApprover = [...this.addApprover, item._id]
    },

    getlocationNamebyId(id) {
      if (typeof id === 'object' && id !== null) {
        id = id._id;
      }
      const name = (this.location_details || []).find(
        (loc) => loc._id === id
      );

      return name ? name.name : "Unknown";
    },

    ActivityLogData(id) {
      return this.axios
        .post("/v2/financialclose/masters/checklist/getlogs/" + id)
        .then((dt) => {
          this.listdata = [];
          if (dt.data.status === "success") {
            this.activityLogById = dt.data.data
          } else throw new Error(dt.data.message);
        })
    },

    resetStage() {
      this.selectedFilterItems = [];
    },
    getItemData(item, key) {
      return item[key];
    },
    showMoreRows() {
      this.visibleRowsitems += this.locationUserList.length - 6;
    },
    showLessRows() {
      this.visibleRowsitems = 6;
    },
    nextStepforLocation(stage) {
      this.locationUserDropdown = stage
    },
    
    selectindividual(id, isChecked) {
        if (isChecked) {
            if (!this.selectItem.includes(id)) {
                this.selectItem.push(id);
            }
        } else {
            this.selectItem = this.selectItem.filter(itemId => itemId !== id);
        }

        console.log("qwer45t6y7uio",this.selectItem, id, isChecked);   
    },

    select(isSelected) {
      if(isSelected){
        this.selectItem=this.paginatedFilteredItems
      }
      else
      {
        this.selectItem=[]
      }
    },
    removeAllSelect() {
      if (this.selectItem.length === this.formatedData.length) this.selectAll = true;
      else this.selectAll = false;
    },
    addTableHeader(item) {
      this.headers.find((x) => x === item ? x.visible = !x.visible : "")
    },
    formateData() {
      let ObjectTemp = {};
      let ArrayTemp = [];
      this.listdata.forEach((item, index) => {
        ObjectTemp = {
          __key: index,
          __formatedkey: index,
          _id: item._id,
          indexno: index + 1,
          ref: item.ref,
          category: item.category,
          subcategory: item.subcategory,
          duedays: item.duedays,
          assertionscount: item.assertions.length,
          assertions: item.assertions,
          location: this.locationfilter(item.location_details),
          locationlist: item.location,
          description: item.description,
          approver: this.nameReturn(item.approver),
          approverids: item.approver,
          preparer: this.nameReturn(item.preparer),
          preparerids: item.preparer,
          tags: item.tags,
          slno: item.slno,
          glcode: item.glcode,
          instruction: item.instruction,
          purpose: item.purpose,
          created_at: item.created_at.split('T')[0],
          updated_at: item.updated_at,
          status: item.status,
        };
        ArrayTemp.push(ObjectTemp);
      });
      this.formatedData = ArrayTemp;
      this.pagecount = Math.ceil(this.storeTotal / this.itemsPerPage);

    },

    exportToCsv() {
      const headers = [
        'Reference',
        'Description',
        'Category',
        'Subcategory',
        'Assertions',
        'Location',
        'DueDays',
        'Purpose',
        'Instruction',
        'Tags',
        'Created_at',
        'Approver',
        'Preparer',
        'GLCode',
      ];

      const data = this.formatedData.map(item => ({
        Reference: item.ref,
        Description: item.description,
        Category: item.category,
        Subcategory: item.subcategory,
        Assertions: Array.isArray(item.assertions) ? item.assertions.join(', ') : item.assertions,
        Location: Array.isArray(item.location) ? item.location.join(', ') : item.location,
        DueDays: item.duedays,
        Purpose: item.purpose,
        Instruction: item.instruction,
        Tags: Array.isArray(item.tags) ? item.tags.join(', ') : item.tags,
        Created_at: item.created_at,
        Approver: Array.isArray(item.approver) ? item.approver.join(', ') : item.approver,
        Preparer: Array.isArray(item.preparer) ? item.preparer.join(', ') : item.preparer,
        GLCode: Array.isArray(item.glcode) ? item.glcode.join(', ') : item.glcode,
      }));
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data, { header: headers });
      headers.forEach((header, index) => {
        const cell_address = XLSX.utils.encode_cell({ c: index, r: 0 });
        if (!ws[cell_address]) return;
        ws[cell_address].s = {
          font: {
            bold: true,
            color: { rgb: "FFFFFF" }
          },
          fill: {
            fgColor: { rgb: "black" }
          }
        };
      });
      ws['!cols'] = headers.map((header) => {
        if (header === 'Description') {
          return { wch: 40 };
        }
        return { wch: 20 };
      });
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'Tasklist-master.xlsx');
    },

    init() {
      this.refreshData();
    },
    refreshData() {
      this.charts = [];
      this.chartdata = {};
      this.listdata = [];
      this.userid = this.$store.state.user.id;
      this.getData();
      this.viewchecklistdialog = false;
    },
    viewChecklist(item) {
      this.addeditdialog = false;
      this.viewchecklistdialog = false;
      this.viewchecklistdata = item;
      let newGlCode = this.chartAcc.filter(item => {
        if (this.viewchecklistdata.glcode?.includes(item.glcode)) {
          return item
        }
      });
      this.viewchecklistdata.glcode = newGlCode
      this.viewchecklistdialog = true;
      //console.log(item);
    },
    getData() {
      this.loading = true;
      return this.axios
        .post("/v2/financialclose/masters/checklist/get")
        .then((dt) => {
          this.listdata = [];
          if (dt.data.status === "success") {
            this.listdata = dt.data.data || [];
            const uniqueAssertions = new Set();
            this.listdata.forEach(item => {
              item.assertions.forEach(assertion => {
                uniqueAssertions.add(assertion);
              });
            });
            uniqueAssertions.add("Others");
            this.possibleassertions = Array.from(uniqueAssertions)
            this.formateData();
          } else throw new Error(dt.data.message);
        })
        .then(() => {
          return this.axios.post(
            "/v2/financialclose/masters/checklist/getformdata"
          );
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.formdata = dt.data.data[0] || {};
            this.location_details = this.formdata.location;
            this.users_details = this.formdata.users;
            this.userlist = {};
            let colors = this.$nova.getColors(
              (this.formdata.users || []).length,
              0.2
            );
            // console.log(colors);
            for (let i = 0; i < (this.formdata.users || []).length; i++) {
              const el = (this.formdata.users || [])[i];
              this.userlist[el._id] = el;
              this.userlist[el._id].color = colors[i];
              this.formateData();
            }
          } else throw new Error(dt.data.message);
        })
        .then(() => {
          this.axios
            .post("/v2/financialclose/location/formselects")
            .then((ele) => {
              let locationtypelist = ele.data.data;
              this.locationtypeItem = locationtypelist[0].type;
            });
          this.loading = false;
        })
        .then(() => {
          this.axios
            .post("/v2/financialclose/masters/chartofaccount/get")
            .then((ele) => {
              this.chartAcc = ele.data.data
              this.categoryAcc = this.chartAcc.map((x) => x.category);
              this.subcategoryAcc = this.chartAcc.map((y) => y.subcategory);
              this.glcodeAcc = this.chartAcc.map((z) => z.glcode)
              this.processCOAData();
            });
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    processCOAData() {
      this.location_details.forEach((location, index) => {
        this.locationBasedGl[location._id] = []
        for (const glItem of this.chartAcc) {
          for (const gllocation of (glItem.location || [])) {
            if (location._id === gllocation._id) {
              this.locationBasedGl[location._id].push(glItem)
            }
            if (index === this.location_details.length - 1) {
              this.setLocationBasedGL(this.locationBasedGl)
            }
          }
        }

      });
    },
    setLocationBasedGL(data) {
      this.locationBasedGl = data;
    },

    addEditForm(dt = {}) {
      this.viewchecklistdialog = false;
      this.locationUserList = dt.locationlist || [];
      this.addeditdata = { ...dt };
      this.addeditdataerror = {};
      this.addeditdialog = true;
    },
    addEditItem() {
      this.addeditloading = true;
      let type = "add";
      let url = "/v2/financialclose/masters/checklist/add";
      if (this.addeditdata._id) {
        type = "edit";
        url =
          "/v2/financialclose/masters/checklist/edit/" + this.addeditdata._id;
      }
      this.addeditdata.assertions = this.addeditdata.assertions && this.addeditdata.assertions.filter(item => item !== "Others");
      if (this.customassertions) {
        const customassertion = this.addeditdata.customassertions.split(',').map(item => item.trim()) || {};
        this.possibleassertions = [...new Set([...customassertion, ...this.possibleassertions])];
        this.addeditdata.assertions = [...new Set([...customassertion, ...this.addeditdata.assertions])];
      }
      this.addeditdata.location = this.locationUserList;
      this.addeditdata.approver = [];
      this.addeditdata.approverids = [];
      this.addeditdata.preparer = [];
      this.addeditdata.preparerids = [];

      let ndt = { ...this.addeditdata };
      delete ndt._id;
      this.axios
        .post(url, { data: ndt })
        .then((dt) => {
          if (dt.data.status === "success") {
            if (type === "add")
              this.$store.commit("sbSuccess", "Checklist item added");
            else this.$store.commit("sbSuccess", "Checklist item updated");
            this.addeditdialog = false;
            this.locationUserList = [];
            this.refreshData();
          } else {
            if (dt.data.data.length > 0) {
              if (dt.data.data[0].index0)
                this.addeditdataerror = dt.data.data[0].index0;
              if (this.addeditdataerror.tags) {
                throw new Error("Tags required");
              } else if (this.addeditdataerror.location) {
                throw new Error("Location required");
              }
            } else throw new Error(dt.data.message);
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.addeditloading = false;
        });
    },
    enableDisableAccess(id, action) {
      this.loading = true;
      let url = "/v2/financialclose/masters/checklist/disable/";
      if (action === "enable") {
        url = "/v2/financialclose/masters/checklist/enable/";
      }
      this.axios
        .post(url + id, {})
        .then((dt) => {
          if (dt.data.status === "success") {
            if (action === "enable") {
              this.$store.commit("sbSuccess", "Item enabled");
              // this.listdata[k].status = true;
            } else {
              this.$store.commit("sbSuccess", "Item disabled");
              // this.listdata[k].status = false;
            }
            this.refreshData();
          } else throw new Error(dt.data.message || "Error performing action");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
   // bulkActionUserdilog(type, selectitems) {
    //  this.selectaction = "associate";
   //   this.bulkActionUser(type, selectitems);
  //  },
   // actionselection(select, type, selectitems) {
    //  if (select === "remove") {
    //    this.bulkactionuserselected = true;
    //    this.bulkActionUser(false, selectitems);
    //  } else {
    //   this.bulkActionUser(type, selectitems);
     // }
    //},
    bulkActionUser(type, value) {
      this.multiselectdata = value;
      let selecteditems = value;
      let existingusers = new Set();
      let possibleids = [];

      if (type) {
        this.bulkactionuserselected = false;
        if (type === "preparer") this.bulkactionuserusertype = "Preparer";
        else if (type === "approver") this.bulkactionuserusertype = "Approver";
      }
      for (const i of this.listdata) {
        if (selecteditems.indexOf(i._id) > -1) {
          if (this.bulkactionuserusertype === "Preparer")
            (i.preparer || []).forEach(existingusers.add, existingusers);
          else if (this.bulkactionuserusertype === "Approver")
            (i.approver || []).forEach(existingusers.add, existingusers);
        }
      }

      if (!this.bulkactionuserselected) {
        for (const i of this.formdata.users || []) {
          this.possibleusers.push(i);
        }
      } else {
        possibleids = Array.from(existingusers);
        this.possibleusers = [];
        for (const i of this.formdata.users || []) {
          if (possibleids.indexOf(i._id) > -1) this.possibleusers.push(i);
        }
      }
      this.bulkactionuserdialog = true;
    },
    // bulkManageUser(action, usertype, userid, selectitems, changeuserid) {
    //   let ndt = {};
    //   this.bulkactionuserloading = true;
    //   if (action === "replace") {
    //     ndt = { fromuserid: userid, touserid: changeuserid, type: action };
    //   } else {
    //     ndt = {
    //       userid: userid,
    //       usertype: (usertype || "").toLowerCase(),
    //       type: action,
    //     };
    //   }
    //   let fdt = { type: "checklist", _id: selectitems };
    //   let successflag = false;
    //   this.axios
    //     .post("/v2/financialclose/masters/checklist/managechecklistuser", {
    //       data: [ndt],
    //       filter: fdt,
    //     })
    //     .then((dt) => {
    //       if (dt.data.status === "success") {
    //         this.bulkactionuserdialog = false;
    //         let successMessage = "";
    //         if (action === "associate") successMessage = "User Added";
    //         else if (action === "remove") successMessage = "User Removed";
    //         else if (action === "move")
    //           successMessage = "Change to " + usertype;
    //         else if (action === "replace") successMessage = "User Replaced";
    //         this.$store.commit("sbSuccess", successMessage);
    //         this.changetouser = "";
    //         successflag = true;
    //       } else {
    //         throw new Error(dt.data.message || "Error changing status");
    //       }
    //     })
    //     .catch((err) => {
    //       this.$store.commit("sbError", err.message || err || "Unknown error!");
    //     })
    //     .finally(() => {
    //       this.bulkactionuserloading = false;
    //       if (successflag) this.refreshData();
    //     });
    // },
    // bulkActionUserlocationdilog(type, value) {
    //   this.multiselectdata = value;
    //   this.locationdialog = true;
    //   this.addLocationerr = {};
    //   this.addApprover = [];
    //   this.addPrepares = [];
    //   this.addLocation = [];
    // },
    nameReturn(ids) {
      let arrayTem = [];
      for (const id of ids) {
        arrayTem.push(this.userlist[id]);
      }
      let newarray = arrayTem.map((i) => i?.name)
      return newarray;
    },
    getLocationName(locationId) {
      const location = this.location_details.find(
        (loc) => loc._id === locationId
      );
      return location ? location.name : "base";
    },
    getPreparesName(id) {
      const location = (this.formdata.users || []).find(
        (loc) => loc._id === id
      );
      return location ? location.name : "Unknown";
    },
    getApproversName(id) {
      const location = (this.formdata.users || []).find(
        (loc) => loc._id === id
      );
      return location ? location.name : "Unknown";
    },
    // getGlCode(id) {
    //   const glcode = (this.chartAcc || []).find(
    //     (gl) => gl._id === id
    //   );
    //   return glcode ? glcode.glcode : " ";
    // },
    addUserLocation(locationlist, preparer, approve) {
      if (locationlist.length === 0 || preparer.length === 0 || approve.length === 0) {
        this.$store.commit("sbError", "locations, preparers, and approves must not be empty");
        return; // Do not proceed if any of them is empty
      }
      locationlist.forEach(locationId => {
        const existingLocationIndex = this.locationUserList.findIndex(userLocation => userLocation.location_id === locationId);

        if (existingLocationIndex !== -1) {
          // If the location exists, update the preparer and approver values
          this.locationUserList[existingLocationIndex].preparer = Array.from(new Set([...this.locationUserList[existingLocationIndex].preparer, ...preparer]));
          this.locationUserList[existingLocationIndex].approver = Array.from(new Set([...this.locationUserList[existingLocationIndex].approver, ...approve]));
        } else {
          // If the location doesn't exist, push a new entry
          const obj = {
            location_id: locationId._id,
            preparer: [...new Set(preparer)],
            approver: [...new Set(approve)],
          };
          this.locationUserList.push(obj);
        }
      });
      this.adduserdialog = false;
      this.menu = false;
      this.locationUserDropdown = 0;
      this.addApprover = [];
      this.addPrepares = [];
      this.addLocation = [];
    },
    // addUserLocationList(location, preparer, approver) {
    //   let locationList = [location];
    //   this.addUserLocation(locationList, preparer, approver);
    //   this.addUsersAppPer = false;
    // },
    // removeUser(location, userId, userType) {
    //   let locationIndex = this.locationUserList.findIndex(x => x.location_id === location);
    //   if (locationIndex !== -1) {
    //     if (userType === 'preparer') {
    //       this.locationUserList[locationIndex].preparer = this.locationUserList[locationIndex].preparer.filter(preparer => preparer !== userId);
    //     } else if (userType === 'approver') {
    //       this.locationUserList[locationIndex].approver = this.locationUserList[locationIndex].approver.filter(approver => approver !== userId);
    //     }
    //   }
    // },
    bulklocationChange(locations, approvers, preparers, ids) {
      this.addLocationerr = {};
      if (
        locations.length === 0 &&
        approvers.length === 0 &&
        preparers.length === 0
      ) {
        const arrays = ["locations", "approvers", "preparers"];

        arrays.forEach((array) => {
          this.addLocationerr[array] = "required";
        });
      } else {
        let data = {
          approver: approvers,
          preparer: preparers,
          location: locations,
        };
        this.axios
          .post("/v2/financialclose/masters/checklist/managelocation", {
            data: data,
            filter: { _id: ids },
          })
          .then((ele) => {
            if (ele.data.status === "success") {
              this.$store.commit(
                "sbSuccess",
                "location users add successfully"
              );
              this.locationdialog = false;
              this.getData();
            } else {
              this.addLocationerr = {};
              if (ele.data.data.length > 0) {
                if (ele.data.data[0].index0)
                  this.addLocationerr = ele.data.data[0].index0;
              } else
                throw new Error(ele.data.message || "Error adding location");
            }
          })
          .catch((err) => {
            this.$store.commit(
              "sbError",
              err.message || err || "Unknown error!"
            );
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    addLocationFun() {
      this.addnewLocationloading = true;
      this.axios
        .post("/v2/financialclose/location/add", { data: [{ "name": this.locationdata.name, "description": this.locationdata.description, "country": this.locationdata.country, "type":this.locationdata.type }] })
        .then((ele) => {
          if (ele.data.status === "success") {
            this.addnewLocation = false;
            this.addnewLocationloading = false;
            this.$store.commit("sbSuccess", "location added successfully");
            this.getData();
          } else {
            if (ele.data.data.length > 0) {
              if (ele.data.data[0].index0)
                this.locationdataerror = ele.data.data[0].index0;
            } else throw new Error(ele.data.message);
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          this.addnewLocationloading = false;
          console.log(err);
        })
        .finally(() => {
          this.addnewLocationloading = false;
        });
    },
    locationfilter(item = []) {
      return item.map(header => header.name);
    },
    deleteLocation(id) {
      this.locationUserList = this.locationUserList.filter((item) => item.location_id !== id)
    },
    // sort coloumn data
    nextStage() {

      this.searchlocation = '';
      this.searchusername = '';
      this.bulkActionStage++;
    },
    submitChanges() {
      this.bulklocationChange(this.addLocation, this.addApprover, this.addPrepares, this.selectItem);
      this.bulkActionStage = 0;
      this.addLocation = [];
      this.addApprover = [];
      this.addPrepares = [];
    },
  },
  watch: {
    "addeditdata.assertions": function () {
      if (this.addeditdata.assertions && this.addeditdata.assertions.indexOf("Others") !== -1) {
        this.customassertions = true;
      }
      else {
        this.customassertions = false;
      }
    },
    "formatedData": function () {
      this.storeTotal = (this.formatedData || []).length
    },
    locationUserList: function () {
      this.possibleGlCodes = [];
      for (var location of this.locationUserList) {
        const glCodes = this.locationBasedGl[location.location_id]?.map(item => item.glcode);
        if (glCodes) {
          this.possibleGlCodes.push(...glCodes);
        }
      }
      this.possibleGlCodes = Array.from(new Set(this.possibleGlCodes));
    },
    page: function (newVal) {
      this.pageInput = newVal;
    },
  },
};
</script>

<style scoped>

.search-width {
  width: 3vh;
}

.location {
  color: #6B67CE;
  background-color: #F4F4FB;
  border: 1px solid #EBEBF7;
  border-radius: 4px;
  padding: 2px 5px !important;
}


.overDuedate {
  padding: 5px 10px;
  opacity: 0px;
  border-radius: 10px;
  border: 1px solid #FBEAEA;
  background: #FDF4F4;
  color: #E85B61
}

.dueDate {
  padding: 5px 8px;
  opacity: 0px;
  border-radius: 24px;
  border: 1px solid #EBEBF7;
  background: #F4F4FB;
  color: #6B67CE
}

.thead-color {
  background-color: #F7F7F7 !important;
}


.light-purple-background {
  background: #F4F4FB;

}

.description-fixed {
  position: absolute;
  background: white;
  width: 100%;
  z-index: 1;
  margin-top: -1.6vh;
  margin-left: -1vh;

}
</style>